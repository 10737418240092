<template>
  <!-- 包车调度 -->
  <div ref="charterDispatchRef" class="charterDispatch">
    <!-- tabs -->
    <div ref="TabsRef" class="Tabs">
      <div class="Tab-line"></div>
      <div
        v-for="(item, index) in tabList"
        :key="item.id"
        :class="index == tabActive ? 'tabActive' : ''"
        class="Tabs-li"
        @click="tabClickFun(item, index)"
      >
        {{ `${item.text}` }}
        <span
          :class="
            item.id == 't1'
              ? 'red'
              : item.id == 't2'
              ? 'yellow'
              : item.id == 't3'
              ? 'blue'
              : item.id == 't4'
              ? 'green'
              : ''
          "
          >{{ `${item.id == "t5" ? "" : "(" + item.value + ")"}` }}</span
        >
      </div>
    </div>
    <div ref="formRef" style="background: #ffffff; padding: 10px 10px 0">
      <div ref="form" class="form-area">
        <el-form
          :inline="true"
          :model="form"
          label-position="left"
          label-width="85px"
          class="demo-form-inline"
        >
          <el-form-item label="订单编号：">
            <el-input
              v-model.trim="form.orderId"
              clearable
              placeholder="请输入订单编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式：">
            <el-input
              v-model.trim="form.passengerPhone"
              maxlength="11"
              clearable
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" size="small" @click="queryFun"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="template">
      <Table
        fixed="right"
        :col-class-type="true"
        :table-data="tableData"
        :title-name="titleName"
        :operation="true"
        :operationWidth="tabActive == 3 ? 200 : ''"
        :table-height="TableHeight"
      >
        <template slot-scope="scope">
          <div>
            <el-button
              v-if="tabActive == 0"
              type="text"
              size="small"
              sort="primary"
              @click="charterDispatchPopupFun(scope.scopeRow, '报价')"
              >报价</el-button
            >
            <el-button
              v-if="tabActive == 1"
              type="text"
              size="small"
              sort="primary"
              @click="charterDispatchPopupFun(scope.scopeRow, '修改报价')"
              >修改报价</el-button
            >
            <el-button
              v-if="tabActive == 0 || tabActive == 1"
              type="text"
              size="small"
              sort="primary"
              @click="cancelFun(scope.scopeRow)"
              >取消</el-button
            >
            <el-button
              v-if="tabActive == 2"
              type="text"
              size="small"
              sort="primary"
              @click="charterDispatchPopupFun(scope.scopeRow, '派车')"
              >派车</el-button
            >
            <el-button
              v-if="tabActive == 2 || tabActive == 3 || tabActive == 4"
              type="text"
              size="small"
              sort="primary"
              @click="charterDispatchPopupFun(scope.scopeRow, '详情')"
              >详情</el-button
            >
            <el-button
              v-if="tabActive == 3"
              type="text"
              size="small"
              sort="primary"
              @click="charterDispatchPopupFun(scope.scopeRow, '改派')"
              >改派</el-button
            >
            <el-button
              v-if="tabActive == 3"
              type="text"
              size="small"
              sort="primary"
              @click="endTaskFun(scope.scopeRow)"
              >结束任务</el-button
            >
            <el-button
              v-if="tabActive == 2 || tabActive == 3"
              type="text"
              size="small"
              sort="primary"
              @click="charterDispatchPopupFun(scope.scopeRow, '退款')"
              >退款</el-button
            >
          </div>
        </template>
      </Table>
      <div ref="pagination" class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="form.pageSize"
          :current-page="form.currentPage"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <charterDispatchPopup ref="charterDispatchPopupRef"></charterDispatchPopup>
  </div>
</template>

<script>
import charterDispatchPopup from "./charterDispatchModel/charterDispatchPopup.vue";
import {
  getHistoryOrderPageListAPI,
  getCharteredSchedulingOrderCountVOAPI,
  quotationPageAPI,
  charterOrderCancelAPI,
  completeItineraryAPI,
  charteredOrderDetailAPI,
} from "@/api/lib/api.js";
export default {
  components: {
    charterDispatchPopup,
  },
  data() {
    return {
      timer: null,
      tabActive: null,
      // orderStatus 枚举如下
      // 5-待报价 10-待支付 20-预订成功 30-已派单 40-行程中
      // 50-待支付额外费用 70-已完成 90-用户已退订 95-退款中
      // 100-后台已退订 110-订单取消
      tabList: [
        {
          text: "待报价",
          value: 0,
          id: "t1",
          name: "quotation",
          orderStatus: [5],
        },
        {
          text: "待支付",
          value: 0,
          id: "t2",
          name: "payment",
          orderStatus: [10],
          charteredType: 3,
        },
        {
          text: "待派车",
          value: 0,
          id: "t3",
          name: "dispatchCar",
          orderStatus: [20],
        },
        {
          text: "服务中",
          value: 0,
          id: "t4",
          name: "service",
          orderStatus: [30, 40, 50],
        },
        {
          text: "已完成",
          value: 0,
          id: "t5",
          name: "history",
          orderStatus: [70],
        },
      ],
      form: {
        orderId: null,
        passengerPhone: null,
        pageSize: 10,
        currentPage: 1,
      },
      total: 0,
      TableHeight: 0,
      tableData: [],
      titleName: [],
      titleNameDefault: [
        {
          title: "联系人",
          props: "passengerName",
          // width: 120,
        },
        {
          title: "联系手机",
          props: "passengerPhone",
          // width: 120,
        },
        {
          title: "出发地",
          props: "startPoint",
          render: (h, params, index) => {
            return h("span", params.startPoint?.name);
          },
          // width: 200,
        },
        {
          title: "目的地",
          props: "endPoint",
          render: (h, params, index) => {
            return h("span", params.endPoint?.name || "-");
          },
          // width: 200,
        },
        {
          title: "出发时间",
          props: "startAt",
          // width: 180,
        },
        {
          title: "用车需求",
          props: "vehicleDemand",
          SpecialJudgment: (res) => {
            return res || "-";
          },
          // width: 180,
        },
        {
          title: "行程说明",
          props: "travelDescription",
          // width: 180,
        },
        {
          title: "价格",
          props: "totalPrices",
          // width: 180,
        },
        {
          title: "支付时间",
          props: "wxTransactionTime",
          // width: 180,
        },
        {
          title: "报价人",
          props: "quotationUsername",
          // width: 180,
        },
        {
          title: "报价时间",
          props: "quotationTime",
          // width: 180,
        },
        {
          title: "提交时间",
          props: "createTime",
          // width: 180,
        },
        {
          title: "订单总额",
          props: "totalPrices",
          // width: 180,
        },
        {
          title: "车辆&驾驶员",
          SpecialJudgment: (res) => {
            if (res.driverName1 || res.driverName2) {
              if (res.driverName2) {
                return `${res.vehiclePlateNumber}（${res.driverName1}）；${res.vehiclePlateNumber}（${res.driverName2}）`;
              } else {
                return `${res.vehiclePlateNumber}（${res.driverName1}）`;
              }
            } else {
              return "-";
            }
          },
          // width: 180,
        },
        {
          title: "完成时间",
          props: "completeAt",
          width: 180,
        },
      ],
    };
  },
  watch: {
    tabActive(v) {
      this.form.orderStatus = this.tabList[v].orderStatus;
      this.form.charteredType = this.tabList[v].charteredType;
      switch (v) {
        case 0:
          this.titleName = this.titleNameDefault.filter((item) => {
            return (
              item.title !== "行程说明" &&
              item.title !== "价格" &&
              item.title !== "支付时间" &&
              item.title !== "报价人" &&
              item.title !== "报价时间" &&
              item.title !== "订单总额" &&
              item.title !== "车辆&驾驶员" &&
              item.title !== "完成时间"
            );
          });
          break;
        case 1:
          this.titleName = this.titleNameDefault.filter((item) => {
            return (
              item.title !== "行程说明" &&
              item.title !== "支付时间" &&
              item.title !== "提交时间" &&
              item.title !== "订单总额" &&
              item.title !== "车辆&驾驶员" &&
              item.title !== "完成时间"
            );
          });
          break;
        case 2:
          this.titleName = this.titleNameDefault.filter((item) => {
            return (
              item.title !== "用车需求" &&
              item.title !== "报价人" &&
              item.title !== "报价时间" &&
              item.title !== "提交时间" &&
              item.title !== "订单总额" &&
              item.title !== "车辆&驾驶员" &&
              item.title !== "完成时间"
            );
          });
          break;
        case 3:
          this.titleName = this.titleNameDefault.filter((item) => {
            return (
              item.title !== "用车需求" &&
              item.title !== "价格" &&
              item.title !== "支付时间" &&
              item.title !== "报价人" &&
              item.title !== "报价时间" &&
              item.title !== "提交时间" &&
              item.title !== "订单总额" &&
              item.title !== "完成时间"
            );
          });
          break;
        case 4:
          this.titleName = this.titleNameDefault.filter((item) => {
            return (
              item.title !== "用车需求" &&
              item.title !== "价格" &&
              item.title !== "支付时间" &&
              item.title !== "报价人" &&
              item.title !== "报价时间" &&
              item.title !== "提交时间"
            );
          });
          break;
        default:
          break;
      }
      this.getDataList();
      this.getCharteredSchedulingOrderCountVOFun();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
    this.tabActive = 0;
    this.timer = setInterval(() => {
      this.getDataList();
      this.getCharteredSchedulingOrderCountVOFun();
    }, 1000 * 60);
  },
  destroyed() {
    clearInterval(this.timer);
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    endTaskFun(scopeRow) {
      this.$confirm(
        "请和司机确认行程未产生额外费用，行程结束后无法再次发起收款。",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          completeItineraryAPI({ charteredOrderId: scopeRow.id }).then(
            (res) => {
              if (res.code == "SUCCESS") {
                this.getDataList();
                this.getCharteredSchedulingOrderCountVOFun();
                this.$message.success("操作成功！");
              }
            }
          );
        })
        .catch(() => {});
    },
    cancelFun(scopeRow) {
      this.$confirm("是否取消该订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          charterOrderCancelAPI({ id: scopeRow.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.getDataList();
              this.getCharteredSchedulingOrderCountVOFun();
              this.$message.success("操作成功！");
            }
          });
        })
        .catch(() => {});
    },
    async charterDispatchPopupFun(scopeRow, type) {
      let data = scopeRow;
      if (type === "报价" || type === "修改报价") {
        const res = await quotationPageAPI({ orderId: scopeRow.id });
        if (res.code === "SUCCESS") {
          data = Object.assign(data, res.data);
        }
      } else if (type === "派车" || type === "改派") {
        this.$refs.charterDispatchPopupRef.queryCph(data);
        this.$refs.charterDispatchPopupRef.queryDriver(data);
      } else if (type === "详情") {
        const res = await charteredOrderDetailAPI({ orderId: scopeRow.id });
        if (res.code === "SUCCESS") {
          data = Object.assign(data, res.data);
        }
      }
      this.$refs.charterDispatchPopupRef.open(data, type);
    },
    getCharteredSchedulingOrderCountVOFun() {
      getCharteredSchedulingOrderCountVOAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.tabList[0].value = res.data.waitPriceSchedulingOrderList.length;
          this.tabList[1].value = res.data.toPaySchedulingOrderList.length;
          this.tabList[2].value = res.data.waitingSchedulingOrderList.length;
          this.tabList[3].value = res.data.drivingOrderList.length;
        }
      });
    },
    // 获取订单列表
    getDataList() {
      let obj = this.deepClone(this.form);
      getHistoryOrderPageListAPI(obj).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
          this.tableData = [];
        }
      });
    },
    // 点击查询按钮的时候
    queryFun() {
      this.form.pageSize = 10;
      this.form.currentPage = 1;
      this.getDataList();
      this.getCharteredSchedulingOrderCountVOFun();
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.getDataList();
      this.getCharteredSchedulingOrderCountVOFun();
    },
    // 分页change方法
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.getDataList();
      this.getCharteredSchedulingOrderCountVOFun();
    },
    // tab点击回调
    tabClickFun(item, index) {
      this.tabActive = index;
      document.querySelector(".Tab-line").style.transform =
        "translateX(" + index * 100 + "%)";
    },
    // 计算表格高度
    computeHeight() {
      const h1 = this.$refs["charterDispatchRef"].clientHeight;
      const h2 = this.$refs["TabsRef"].clientHeight;
      const h3 = this.$refs["formRef"].clientHeight;
      this.TableHeight = h1 - h2 - h3 - 100;
    },
  },
};
</script>

<style scoped lang="scss">
.tabActive {
  color: #336ffe !important;
}
.red {
  color: #d40000;
}
.yellow {
  color: #ffbd00;
}
.blue {
  color: #336ffe;
}
.green {
  color: #67c23a;
}
.charterDispatch {
  position: relative;
  height: 100%;
  background: #ffffff;
  .template {
    margin-top: 24px;
    padding: 0 10px;
    height: calc(100% - 24px - 54px - 80px);
  }
  .Tabs {
    height: 54px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(52, 52, 52, 0.1);
    display: flex;
    position: relative;
    .Tab-line {
      width: 120px;
      height: 2px;
      background: #336ffe;
      display: block;
      position: absolute;
      z-index: 0;
      bottom: 0px;
      left: 0;
      transition: all 0.3s;
    }
    .Tabs-li {
      text-align: center;
      width: 90px;
      height: 100%;
      line-height: 54px;
      padding: 0 15px;
      color: #000000;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
